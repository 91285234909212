<template>
    <div class="container_con">
        <el-card>
            <div class="search">
                <div>
                    <el-button type="primary" @click="$router.push('/addmenuItem')" v-if="have_auth('/menu/add')">+ 新增</el-button>
                </div>
               <!-- <div>
                    <el-input icon="el-icon-search" v-model="queryParams.title" @keyup.enter.native="handleQuery" placeholder="请输入搜索内容" clearable
                        style="width:300px;margin-right:10px"></el-input>
                    <el-button type="primary" @click="handleQuery()">搜索</el-button>
                </div> -->
            </div>
            <div style="margin-top:20px">
                <el-table :data="list" v-loading="loading" style="width: 100%" row-key="id"
        :tree-props="{children: '_child', hasChildren: 'hasChildren'}">
                    <el-table-column prop="title" label="名称"  width="240"/>
                    <el-table-column prop="icon" label="图标" align="center" width="100">
                      <template v-slot="scope">
                        <img :src="scope.row.icon" style="height: 30px;" v-if="scope.row.icon" alt="">
                      </template>
                    </el-table-column>
                    <el-table-column label="类型">
                        <template v-slot="scope">
                          {{type_texts[scope.row.type]}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="url" label="url" />
                    <el-table-column prop="remark" label="描述" />
                    <el-table-column prop="sort" label="排序" />
                    <el-table-column fixed="right" label="操作" width="150">
                        <template v-slot="scope">
                            <el-button type="text" size="small" @click="handleUpdate(scope.row)" v-if="have_auth('/menu/edit')">编辑</el-button>
                            <el-button type="text" size="small" @click="handleDelete(scope.row)" v-if="have_auth('/menu/del')">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-card>
    </div>
    <!-- <pagination
      v-show="total>0"
      :total="total"
      v-model:page="queryParams.page"
      v-model:limit="queryParams.size"
      @pagination="getList"
    /> -->
</template>

<script>
    export default {
        name: "department",
        data() {
            return {
                // 列表模板
                loading: false,
                // 查询参数
                queryParams: {
                    page: 1,
                    size: 10,
                    title: ''
                },
                total:0,
                list:[],
                type_texts:{
                    '1':'菜单',
                    '2':'导航按钮',
                    '3':'按钮'
                }
            };
        },
        created() {
            this.getList();
        },
        methods: {
            /** 修改按钮操作 */
            handleUpdate(row) {
              this.$router.push('/addmenuItem?id='+row.id)
            },
            /** 删除按钮操作 */
            handleDelete(row) {
              const _this=this;
              const ids = row.id || this.ids;
              this.$confirm('是否确认删除?', "警告", {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "warning"
                }).then(function() {
                  
                  _this.$httpGet("/backend/Menu/del", {ids:ids}).then((res) => {
                      if (res.status == 200) {
                          _this.getList();
                          _this.$message.success(res.message);
                      } else {
                          _this.$message.error(res.message);
                      }
                  }).catch((err) => {
                      console.log(err);
                  });
                })
            },
            /** 查询列表 */
            getList() {
                this.loading = true;
                this.$httpGet("/backend/Menu/index", this.queryParams).then((res) => {
                    if (res.status == 200) {
                        this.loading = false;
                        this.list = res.data.menu_tree;
                        this.total = res.data.total;
                    } else {
                        this.$message.error(res.message);
                    }
                    this.loading = false;
                }).catch((err) => {
                    console.log(err);
                    this.loading = false;
                });
            },
            /** 搜索按钮操作 */
            handleQuery() {
                this.queryParams.page = 1;
                this.getList();
            }
        },
    };
</script>

<style lang="scss" scoped></style>
